@import "../../../styles/abstracts";

.cursor {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    transition: opacity 0.5s var(--primary-curve);
  }

  &.clicked:before {
    animation: click-spring 0.7s var(--primary-curve);
  }

  &.dragging:before {
    opacity: 1;
    transform: scale3d(1.15, 1.15, 1.15) translate3d(-50%, -50%, 0);
    transition: transform 0.7s var(--primary-curve), opacity 0s;
  }

  &.fading {
    opacity: 0;
  }

  @include before {
    position: absolute;
    width: var(--cursor-dimension);
    height: var(--cursor-dimension);
    background: var(--white);
    border-radius: 50%;
    opacity: 0;
    transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0);
    transform-origin: left top;
    transition: transform 0.7s var(--primary-curve), opacity 0s 0.7s;
  }
}

//Wrapper container to clip occasional flickers during childs clip-path animation

.wrapper {
  display: inline-block;
  clip-path: circle(50%);
  transform: translate(-50%, -50%);
}

.cursorRing {
  display: inline-block;
  transform: translateZ(0);
  clip-path: circle(50%);
  animation: cursor-ring-in 0.7s var(--primary-curve);

  & > span {
    @include flexCenter;
    width: var(--cursor-dimension);
    height: var(--cursor-dimension);
    padding: 40px;
    background: var(--white);
    opacity: 1;
    font-size: var(--title-4);
    color: var(--black);
    line-height: 0.95;
    text-align: center;
    user-select: none;
    animation: label-in 0.7s var(--primary-curve);
  }
}

@keyframes cursor-ring-in {
  from {
    clip-path: circle(0%);
  }
}

@keyframes label-in {
  from {
    opacity: 0;
  }
}

@keyframes click-spring {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0);
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2) translate3d(-50%, -50%, 0);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0);
  }
}
