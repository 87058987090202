.animatedLinesContainer {
  position: relative;

  &:not(.noTextTransform) {
    text-transform: uppercase;
  }
}

.animatedLines {
  & > span {
    position: absolute;
    right: 0;
    left: 0;
    overflow: hidden;

    & > span {
      position: absolute;
      animation: chars-in 1.25s var(--primary-curve) forwards;
      will-change: transform;
      transform: translate3d(0, 100%, 0);
    }
  }
}

.lg {
  font-size: var(--lg-paragraph-text);
  line-height: 1.25;
}

@keyframes chars-in {
  to {
    transform: var(--base-translate3d);
  }
}

.passive {
  opacity: 0;
}
