// BREAKPOINTS
$screen-small: 576px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-extra-large: 1228px;
$screen-desktop: 1380px;

@mixin smallHeightLargeScreen {
  @media screen and (min-width: 992px) and (max-height: 840px) {
    @content;
  }
}

@mixin smallHeightPC {
  @media screen and (min-width: 1380px) and (max-height: 880px) {
    @content;
  }
}

@mixin appGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;

  @media screen and (min-width: #{$screen-large}) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 25px;
  }
}

@mixin fullVertical {
  grid-column-start: 1;
  grid-column-end: 7;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-end: 13;
  }
}

@mixin fullAbsolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin before($content: "") {
  &:before {
    content: $content;
    @content;
  }
}

@mixin after($content: "") {
  &:after {
    content: $content;
    @content;
  }
}

@mixin hoverUnderline($underlineColor: var(--main-color)) {
  position: relative;

  @include after {
    position: absolute;
    right: 0;
    bottom: -5px;
    left: 0;
    display: inline-block;
    height: 1px;
    background: $underlineColor;
    transform: scale3d(0, 1, 1);
    transform-origin: right;
    transition: transform 0.65s var(--primary-curve);
  }

  &:hover:after {
    @media screen and (min-width: #{$screen-large}) {
      transform: var(--base-scale3d);
      transform-origin: left;
    }
  }
}
