@import "../../../styles/abstracts";

.clickArea {
  @include fullAbsolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: #{$screen-large}) {
    display: none;
  }
}

.title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: var(--white);
  font-size: var(--mobile-click-area-text);
  line-height: 0.95;
  border-radius: 50%;

  & > span {
    text-align: center;
    width: 90px;
  }
}
