@import "../../../styles/abstracts";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  overflow: auto;
  opacity: 1;
  clip-path: inset(100% 0 0 0);
  transition: opacity 0.7s var(--primary-curve);
  animation: mask-in 0.7s var(--primary-curve) forwards;

  @media screen and (min-width: #{$screen-large}) {
    animation-delay: 0.4s;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    @media screen and (min-width: #{$screen-large}) {
      display: none;
    }
  }
}

.contentsContainer {
  position: relative;
}

.contents {
  padding: 0 var(--layout-horizontal-padding);
  color: var(--white);
  overflow: hidden;

  @media screen and (min-width: #{$screen-large}) {
    position: sticky;
    top: 0;
    height: 100vh;
  }
}

.header {
  height: var(--modal-header-height);
}

.headerGrid {
  @include appGrid;
  padding-top: 42px;
  font-size: var(--title-4);
}

.headerTitle {
  grid-column-start: 1;
  grid-column-end: 4;
}

.headerLiveLink {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
    grid-column-start: 4;
    grid-column-end: 7;
  }
}

.liveLink {
  @include hoverUnderline(var(--white));
}

.headerProjectName {
  grid-column-start: 1;
  grid-column-end: 6;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 7;
    grid-column-end: 10;
  }
}

.close {
  @include hoverUnderline(var(--white));
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row: 1;
  justify-self: end;
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  color: var(--white);
  cursor: pointer;

  &:focus {
    color: var(--white);
  }
}

.body {
  @include appGrid;
  margin-top: 74px;

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 70px;
    height: calc(100vh - var(--modal-header-height) - 130px);
  }
}

.details {
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-end: 6;
  }
}

.bodyLiveLink {
  margin: 25px 0 100px;
  font-size: var(--title-4);

  @media screen and (min-width: #{$screen-large}) {
    display: none;
  }
}

.description {
  font-size: var(--lg-description-text);
  line-height: 1.11;
}

.descriptionList {
  position: relative;
  margin: 26px 0 56px;

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 26px 0 0;
  }

  @include before {
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    height: 1px;
    background: var(--transparent-white);
    transform: scale3d(0, 1, 1);
    transform-origin: left;
    animation: border-stretch 0.9s var(--primary-curve) 0.5s forwards;

    @media screen and (min-width: #{$screen-large}) {
      animation-delay: 0.9s;
    }
  }
}

.descriptionBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
  font-size: var(--title-4);
  line-height: 1.2;
  margin-bottom: 1px;

  @include after {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 1px;
    background: var(--transparent-white);
    transform: scale3d(0, 1, 1);
    transform-origin: left;
    animation: border-stretch 0.9s var(--primary-curve) 0.5s forwards;

    @media screen and (min-width: #{$screen-large}) {
      animation-delay: 0.9s;
    }
  }
}

.mediaContainer {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
    grid-column-start: 7;
    grid-column-end: 13;
  }
}

.mobileMediaContainer {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-bottom: 15px;

  & > img {
    margin-bottom: 10px;
  }

  @media screen and (min-width: #{$screen-large}) {
    display: none;
  }
}

.media {
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;

  img {
    max-width: none;
    height: calc(100vh - var(--modal-header-height) - 130px);
    clip-path: inset(100% 0 0 0);
    animation: mask-in 0.9s var(--primary-curve) 0.9s forwards;

    &:not(:last-of-type) {
      margin-right: 50px;
    }
  }
}

.scrollWidthRetainer {
  width: 10px;
  flex-shrink: 0;
}

@keyframes mask-in {
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes border-stretch {
  to {
    transform: var(--base-scale3d);
  }
}
