@import "../../../styles/abstracts";

.emailAddressBigText {
  margin-bottom: 12px;

  & > span {
    &:before {
      top: 1.085em;
    }

    &:after {
      top: 1.055em;
    }

    &:nth-of-type(2) {
      top: 0.01em;
    }
  }

  @media screen and (min-width: #{$screen-large}) {
    margin-bottom: 0;
  }
}

.clipboardText {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: inline-block;
  }
}

.areaCursor {
  & > span > span > span {
    padding: 10px;
  }
}

.emailToAddress {
  display: block;
  width: 300px;

  @media screen and (min-width: #{$screen-large}) {
    display: none;
  }
}
