@import "../../../styles/abstracts";

.bigAction {
  display: inline-block;
  user-select: none;

  & > span {
    clip-path: inset(0px 0px 0px 0px);

    @include before {
      display: inline-block;
      height: 0.07em;
      background: var(--main-color);
      position: absolute;
      top: 1.05em;
      right: 0;
      left: 0;
      transform: var(--base-scale3d);
      transition: transform 0.7s var(--primary-curve);
      transform-origin: left;
    }

    @include after {
      display: inline-block;
      height: 0.11em;
      width: 70px;
      background: var(--light-grey);
      position: absolute;
      top: 1.02em;
      left: -70px;
      z-index: 10;
      transform: var(--base-translate3d);
      transition: left 1.25s var(--primary-curve);
      pointer-events: none;
    }

    &:nth-of-type(2) {
      &:before,
      &:after {
        transition-delay: 0.1s;
      }
    }

    @media screen and (min-width: #{$screen-desktop}) {
      clip-path: inset(0px -1px 0px 0px);
    }
  }

  &:not(.passive) {
    &:hover {
      & > span {
        &:after {
          left: calc(100% + 75px);
        }
      }
    }
  }

  &.passive {
    & > span:before {
      transform: scale3d(0, 1, 1);
    }
  }
}

.bigActionBtn {
  background-color: transparent;
  padding-bottom: 0.55em;
  text-align: inherit;
  border: none;
  outline: none;
  color: var(--main-color);
  cursor: pointer;

  &.withAreaCursor {
    cursor: none;
  }

  &:focus {
    color: var(--main-color);
  }
}

.inverse {
  & > span:after {
    background: var(--black);
  }
}

.textsSeperator {
  display: block;
  height: 10px;

  @media screen and (min-width: #{$screen-large}) {
    height: 20px;
  }
}
