@import "../../../styles/abstracts";

.sendMessageContainer {
  position: relative;
}

.sendMessage {
  @include appGrid;
  padding: var(--send-message-padding);
}

.titleTwo {
  grid-column-start: 3;
  grid-column-end: 7;
  max-width: 155px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 3;
    grid-column-end: 5;
    max-width: 195px;
  }
}

.titleThree {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
    grid-column-start: 9;
    grid-column-end: 12;
    max-width: 190px;
  }
}

.emailAddress {
  margin-top: 70px;
  @include fullVertical;

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 120px;
  }
}
