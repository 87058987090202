@import "../../../styles/abstracts";

.project {
  @include fullVertical;
  @include appGrid;

  &:not(:last-child) {
    margin-bottom: 45px;
  }
}

.details {
  grid-column-start: 1;
  grid-column-end: 7;

  @media screen and (min-width: #{$screen-large}) {
    padding-right: calc((100% - 125px) / 6);
  }
}

.shortDescription {
  max-width: 300px;
  margin: 30px 0 35px 0;

  @media screen and (min-width: #{$screen-large}) {
    margin: 68px 0 0 calc(((100% - 100px) / 5) + 25px);
  }
}

.cover {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 7;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 7;
    grid-column-end: 13;
  }
}

.coverImageContainer {
  height: 430px;
  width: 100%;

  @media screen and (min-width: #{$screen-large}) {
    height: calc(((100vw - var(--full-screen-gutters)) / 2) * 1.4);
    min-height: 120vh;
  }
}

.coverImage {
  width: calc(100vw - (var(--layout-horizontal-padding) * 2));
  height: 540px;

  @media screen and (min-width: #{$screen-large}) {
    width: calc((100vw - (var(--layout-horizontal-padding) * 2)) / 2);
    height: calc(((100vw - var(--full-screen-gutters)) / 2) * 1.57);
    min-height: 150vh;
  }
}

.projectModalExit {
  opacity: 0;
}
